import React from 'react'
import Layout from '../components/Layout'
import Contact from '../components/Contact'
import SEO from '../components/SEO'

const contactPage = () => {
  return (
    <>
      <Layout>
        <SEO title="お問い合わせ" />
        <Contact title="お問い合わせ" />
      </Layout>
    </>
  )
}
export default contactPage
