import React from 'react'
import Banner from './Banner'
import styled from 'styled-components'

const Contact = ({ title }) => {
  return (
    <section className="posts">
      <h3 className="posts-title">{title}</h3>
      <div className="posts-center">
        {/* posts column */}
        <article>
          <Wrapper>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSdbNfo2hgichhPLu3-qWyYlE2Zq4VoL3KyTuqxQ-aUczsoVNQ/viewform?embedded=true"
              height="1200"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              className="googleForm"
            >
              Loading…
            </iframe>
          </Wrapper>
        </article>
        {/* banner column */}
        <article>
          <Banner />
        </article>
      </div>
    </section>
  )
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 8vh;
  @media (min-width: 1170px) {
    .container {
      margin-left: -2rem;
      margin-right: -2rem;
    }
  }
  .googleForm {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`

export default Contact
